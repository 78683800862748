import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-content">
                    <div className="sitemap">
                        <ul>
                            <li><Link to="/">ホーム</Link></li>
                            <li><Link to="/service">サービス</Link></li>
                            <li><Link to="/about">会社概要</Link></li>
                            <li><Link to="/news">ニュース</Link></li>
                            <li><Link to="/contact">お問い合わせ</Link></li>
                            <li><Link to="/blog">ブログ</Link></li>
                        </ul>
                    </div>
                    <div className="footer-bottom">
                        <p>©2024 - Uematsu lnc.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
