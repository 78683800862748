import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getBlogs } from '../api/microcms';
import BlogItem from '../components/BlogItem';
import '../assets/styles/blog.css'; 
import 'pace-js/themes/blue/pace-theme-minimal.css';
import Pace from 'pace-js';

const BlogList = ({ setIsLoading }) => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const blogsPerPage = 3;

  useEffect(() => {
    const fetchBlogs = async (page) => {
      Pace.start();
      setIsLoading(true);  
      const data = await getBlogs(page, blogsPerPage);
      setBlogs(data.contents);
      setTotalPages(Math.ceil(data.totalCount / blogsPerPage));
      setIsLoading(false);  
      Pace.stop();
    };
    fetchBlogs(currentPage);
  }, [setIsLoading, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="blog-container">
      <Helmet>
        <title>最近の記事 | 株式会社Uematsu</title>
        <meta name="description" content="最新のブログ記事をチェックしてください。" />
        <meta name="keywords" content="Uematsu, IT, 中小企業, DX, デジタルトランスフォーメーション, ブログ, 最近の記事" />
      </Helmet>
      <h1>最近の記事</h1>
      <ul className="blog-list">
        {blogs.map((blog) => (
          <BlogItem key={blog.id} blog={blog} />
        ))}
      </ul>
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogList;
