// components/CommonHelmet.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const CommonHelmet = ({ pageTitle, pageDescription, keywords = [] }) => {
    const defaultTitle = "株式会社Uematsu - ホームページ";
    const defaultDescription = "株式会社Uematsu - 日本の中小企業をIT企業に変える会社";

    const companyName = "株式会社Uematsu";
    const defaultKeywords = ["Uematsu", "IT", "中小企業", "DX", "デジタルトランスフォーメーション"];

    const title = pageTitle ? `${companyName}｜${pageTitle}` : defaultTitle;
    const description = pageDescription ? pageDescription : defaultDescription;
    const allKeywords = [...defaultKeywords, ...keywords].join(', ');

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={allKeywords} />
        </Helmet>
    );
};

export default CommonHelmet;
