import React from 'react';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const News = () => {
    return (
        <div className="container">
            <CommonHelmet
                pageTitle="ニュース"
                keywords={["プレリリース", "新着情報"]}
            />
            <h1>ニュース</h1>
            <p>最新のニュースとお知らせをここに掲載します。</p>
            <p>2024年7月1日: 新しいサービスを開始しました。</p>
            <p>2024年6月15日: ウェブサイトをリニューアルしました。</p>
        </div>
    );
}

export default News;
