import React from 'react';
import { Helmet } from 'react-helmet-async';

const BlogHelmet = ({ blogTitle, blogDescription, blogKeywords }) => {
    const companyName = "株式会社Uematsu";
    const defaultDescription = "株式会社Uematsu - 日本の中小企業をIT企業に変える会社";
    const defaultKeywords = ["Uematsu", "IT", "中小企業", "DX", "デジタルトランスフォーメーション"];

    const title = blogTitle ? `${companyName}｜${blogTitle}` : companyName;
    const description = blogDescription ? blogDescription : defaultDescription;
    const keywords = blogKeywords ? [...defaultKeywords, ...blogKeywords].join(', ') : defaultKeywords.join(', ');

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
};

export default BlogHelmet;
