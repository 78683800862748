import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogsByCategory, getCategory } from '../api/microcms';
import BlogItem from '../components/BlogItem';
import NoResults from '../components/NoResults';
import CategoryHelmet from '../assets/helmet/CategoryHelmet';
import '../assets/styles/blog.css';
import 'pace-js/themes/blue/pace-theme-minimal.css';
import Pace from 'pace-js';

const CategoryList = ({ setIsLoading }) => {
  const { categoryId } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [category, setCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const blogsPerPage = 10;

  useEffect(() => {
    const fetchBlogs = async (page) => {
      Pace.start();
      setIsLoading(true); 
      const data = await getBlogsByCategory(categoryId, page, blogsPerPage);
      setBlogs(data.contents);
      setTotalPages(Math.ceil(data.totalCount / blogsPerPage));
      setIsLoading(false); 
      Pace.stop();
    };

    const fetchCategory = async () => {
      const data = await getCategory(categoryId);
      setCategory(data);
    };

    fetchBlogs(currentPage);
    fetchCategory();
  }, [categoryId, setIsLoading, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="blog-container">
      <CategoryHelmet
        categoryName={category ? category.name : ''}
        categoryDescription={category ? category.explanation : ''}
      />
      <h1>{category ? `${category.name}` : ''}</h1>
      {category && (category.explanation || category.image) && (
        <div className="category-info">
          {category.explanation && (
            <div className="category-description">
              <p>{category.explanation}</p>
            </div>
          )}
          {category.image && (
            <div className="category-image">
              <img src={category.image.url} alt={category.name} />
            </div>
          )}
        </div>
      )}
      {blogs.length === 0 ? (
        <NoResults query={category ? category.name : 'このカテゴリ'} />
      ) : (
        <ul className='blog-list'>
          {blogs.map((blog) => (
            <BlogItem key={blog.id} blog={blog} />
          ))}
        </ul>
      )}
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryList;
