import React from 'react';
import ContactForm from '../components/ContactForm';
import '../assets/styles/contact.css';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const Contact = () => {
    return (
        <div className="container">
            <CommonHelmet
                pageTitle="コンタクト"
                keywords={["連絡先", "お問い合わせ", "コンタクト"]}
            />
            <h1>お問い合わせ</h1>
            <p>ご質問やご相談は以下のフォームからお問い合わせください。</p>
            <ContactForm />
        </div>
    );
}

export default Contact;
