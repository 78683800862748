import React from 'react';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const Service = () => {
    return (
        <div className="container">
            <CommonHelmet
                pageTitle="サービス"
                keywords={["IT人材教育", "サービス解説"]}
            />
            <h1>サービス</h1>
            <p>我々のサービスはIT人材育成に特化しています。</p>
            <p>提供するサービスについて詳しく説明する。</p>
        </div>
    );
}

export default Service;
