import React from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/styles/contact.css';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const ThankYou = () => {
  const location = useLocation();
  const { formData } = location.state || {};

  return (
    <div className="container">
        <CommonHelmet
            pageTitle="お問い合わせ完了"
        />
      <h1>お問い合わせありがとうございました</h1>
      <p>お問い合わせ内容が送信されました。折り返しご連絡いたしますので、しばらくお待ちください。</p>
      {formData && (
        <div className="thank-you-details">
          <h2>送信内容</h2>
          <div><strong>メールアドレス:</strong> {formData.email}</div>
          <div><strong>姓（漢字）:</strong> {formData.lastName}</div>
          <div><strong>名（漢字）:</strong> {formData.firstName}</div>
          <div><strong>会社名・法人名:</strong> {formData.company}</div>
          <div><strong>所属部署:</strong> {formData.department}</div>
          <div><strong>役職:</strong> {formData.position}</div>
          <div><strong>お問い合わせ件名:</strong> {formData.subject}</div>
          <div><strong>お問合せ内容:</strong> {formData.message}</div>
          <div><strong>電話番号:</strong> {formData.phone}</div>
        </div>
      )}
    </div>
  );
};

export default ThankYou;
