import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/contact.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    lastName: '',
    firstName: '',
    company: '',
    department: '',
    position: '',
    subject: '',
    message: '',
    phone: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(process.env.REACT_APP_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      navigate('/thank-you', { state: { formData } });
    } else {
      const result = await response.json();
      alert(result.message);
    }
  };

  return (
    <form id="contactForm" onSubmit={handleSubmit}>
      <label htmlFor="email">メールアドレス*</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />

      <div className="horizontal-group">
        <div className="form-group">
          <label htmlFor="lastName">姓（漢字）*</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="firstName">名（漢字）*</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <label htmlFor="company">会社名・法人名*</label>
      <input
        type="text"
        id="company"
        name="company"
        value={formData.company}
        onChange={handleChange}
        required
      />

      <div className="horizontal-group">
        <div className="form-group">
          <label htmlFor="department">所属部署</label>
          <input
            type="text"
            id="department"
            name="department"
            value={formData.department}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="position">役職</label>
          <input
            type="text"
            id="position"
            name="position"
            value={formData.position}
            onChange={handleChange}
          />
        </div>
      </div>

      <label htmlFor="subject">お問い合わせ件名*</label>
      <input
        type="text"
        id="subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        required
      />

      <label htmlFor="message">お問合せ内容*</label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
      ></textarea>

      <label htmlFor="phone">電話番号</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
      />

      <button type="submit">送信</button>
    </form>
  );
};

export default ContactForm;
