import React from 'react';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const Home = () => {
    return (
        <div>
            <CommonHelmet
                pageTitle="ホーム"
                keywords={["ホーム", "ウェブサイト"]}
            />
            <section id="hero-image">
                <div className="container hero-text">
                    <img src={`${process.env.PUBLIC_URL}/logo-title.webp`} alt="ロゴ" className="hero-logo" />
                    <h1>日本の中小企業をすべてIT企業に変える</h1>
                    <a href="/service" className="detail-button">詳しくはこちら</a>
                </div>
            </section>

            <section id="service">
                <div className="container">
                    <h1>サービス</h1>
                    <h3>IT人材育成</h3>
                    <img src={`${process.env.PUBLIC_URL}/logo-corp.webp`} alt="" className="hero-logo" />
                    <p>提供するサービスについて詳しく説明する</p>
                </div>
            </section>

            <section id="about">
                <div className="container">
                    <h2>会社概要</h2>
                    <p>会社についてくわしく説明する</p>
                    <a href="/about" className="detail-button">詳しくはこちら</a>
                </div>
            </section>

            <section id="news">
                <div className="container">
                    <h2>ニュース</h2>
                    <p>最新情報やお知らせを掲載する</p>
                    <a href="/news" className="detail-button">詳しくはこちら</a>
                </div>
            </section>
        </div>
    );
}

export default Home;
