import axios from 'axios';

const API_KEY = process.env.REACT_APP_API_KEY;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const ENDPOINT = `https://${SERVICE_ID}.microcms.io/api/v1`;

export const getBlogs = async (page = 1, limit = 2) => {
    try {
      const response = await axios.get(`${ENDPOINT}/blogs`, {
        headers: {
          'X-API-KEY': API_KEY,
        },
        params: {
          offset: (page - 1) * limit,
          limit: limit,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs:', error);
      return { contents: [], totalCount: 0 };
    }
  };

export const getCategories = async () => {
  try {
    const response = await axios.get(`${ENDPOINT}/categories`, {
      headers: {
        'X-API-KEY': API_KEY,
      },
    });
    return response.data.contents;
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};

export const getBlogsByCategory = async (categoryId, page = 1, limit = 2) => {
    try {
      const response = await axios.get(`${ENDPOINT}/blogs`, {
        headers: {
          'X-API-KEY': API_KEY,
        },
        params: {
          filters: `category[equals]${categoryId}`,
          offset: (page - 1) * limit,
          limit: limit,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs by category:', error);
      return { contents: [], totalCount: 0 };
    }
  };

  export const getCategory = async (categoryId) => {
    try {
      const response = await axios.get(`${ENDPOINT}/categories/${categoryId}`, {
        headers: {
          'X-API-KEY': API_KEY,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category:', error);
      return null;
    }
  };

export const getBlogsByQuery = async (query, page = 1, limit = 2) => {
    try {
      const response = await axios.get(`${ENDPOINT}/blogs`, {
        headers: {
          'X-API-KEY': API_KEY,
        },
        params: {
          filters: `title[contains]${query}`,
          offset: (page - 1) * limit,
          limit: limit,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs by query:', error);
      return { contents: [], totalCount: 0 };
    }
  };