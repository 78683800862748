import React from 'react';
import { Helmet } from 'react-helmet-async';

const CategoryHelmet = ({ categoryName, categoryDescription }) => {
    const companyName = "株式会社Uematsu";
    const defaultKeywords = ["Uematsu", "IT", "中小企業", "DX", "デジタルトランスフォーメーション"];

    const title = categoryName ? `${companyName}｜${categoryName}` : companyName;
    const description = categoryDescription ? categoryDescription : `${categoryName}についてのページです`;
    const keywords = categoryName ? [...defaultKeywords, categoryName].join(', ') : defaultKeywords.join(', ');

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
};

export default CategoryHelmet;
