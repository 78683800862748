import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getBlogsByQuery } from '../api/microcms';
import BlogItem from '../components/BlogItem';
import NoResults from '../components/NoResults';
import '../assets/styles/blog.css';
import 'pace-js/themes/blue/pace-theme-minimal.css';
import Pace from 'pace-js';

const SearchResults = ({ setIsLoading }) => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const blogsPerPage = 10;

  useEffect(() => {
    const fetchBlogs = async (page) => {
      Pace.start();
      setIsLoading(true);
      setLoading(true);
      const data = await getBlogsByQuery(query, page, blogsPerPage);
      setBlogs(data.contents);
      setTotalPages(Math.ceil(data.totalCount / blogsPerPage));
      setIsLoading(false);
      setLoading(false);
      Pace.stop();
    };

    if (query) {
      fetchBlogs(currentPage);
    }
  }, [query, setIsLoading, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="blog-container">
      <Helmet>
        <title>{`検索結果: ${query} | 株式会社Uematsu`}</title>
        <meta name="description" content={`"${query}"に関連する検索結果です。`} />
        <meta name="keywords" content={`Uematsu, IT, 中小企業, DX, デジタルトランスフォーメーション, 検索, ${query}`} />
      </Helmet>
      <h1>検索結果: {query}</h1>
      {loading ? null : blogs.length === 0 ? (
        <NoResults query={query} />
      ) : (
        <ul className="blog-list">
          {blogs.map((blog) => (
            <BlogItem key={blog.id} blog={blog} />
          ))}
        </ul>
      )}
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
