import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import BlogHelmet from '../assets/helmet/BlogHelmet';
import '../assets/styles/blog.css';
import 'pace-js/themes/blue/pace-theme-minimal.css';
import Pace from 'pace-js';

const BlogDetail = ({ setIsLoading }) => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    Pace.start();
    setIsLoading(true);

    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://${process.env.REACT_APP_SERVICE_ID}.microcms.io/api/v1/blogs/${id}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setBlog(response.data);
        Pace.stop();
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching blog:', error);
        Pace.stop();
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [id, setIsLoading]);

  if (!blog) return null;

  return (
    <div className="blog-container">
      <BlogHelmet
        blogTitle={blog.title}
        blogDescription={blog.description || blog.excerpt || ""}
        blogKeywords={blog.keywords || []}
      />
      <div className="blog-detail">
        <h1>{blog.title}</h1>
        {blog.eyecatch && (
          <img src={blog.eyecatch.url} alt={blog.title} />
        )}
        <div className="content" dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>
    </div>
  );
};

export default BlogDetail;
