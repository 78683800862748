import React, { useState, createRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { HelmetProvider } from 'react-helmet-async'; // 追加
import Header from './components/Header';
import BlogHeader from './blog/BlogHeader'; 
import Footer from './components/Footer';
import Home from './pages/Home';
import Service from './pages/Service';
import About from './pages/About';
import News from './pages/News';
import Contact from './pages/Contact';
import ThankYou from './pages/ThankYou';
import BlogList from './blog/BlogList';
import BlogDetail from './blog/BlogDetail';
import CategoryList from './blog/CategoryList'; 
import SearchResults from './blog/SearchResults'; 
import './assets/styles/style.css';

function App() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);  

    // ブログページかどうかをチェックする関数
    const isBlogPage = location.pathname.startsWith('/blog') || location.pathname.startsWith('/search');

    // refを作成
    const nodeRef = createRef();

    return (
        <HelmetProvider>
            <div className="App">
                {!isBlogPage && <Header />}
                {isBlogPage && <BlogHeader />}
                <main>
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={{ enter: 500, exit: 300 }}
                            nodeRef={nodeRef}
                        >
                            <div ref={nodeRef}>
                                <Routes location={location}>
                                    <Route path="/" element={<Home setIsLoading={setIsLoading} />} />
                                    <Route path="/service" element={<Service setIsLoading={setIsLoading} />} />
                                    <Route path="/about" element={<About setIsLoading={setIsLoading} />} />
                                    <Route path="/news" element={<News setIsLoading={setIsLoading} />} />
                                    <Route path="/contact" element={<Contact setIsLoading={setIsLoading} />} />
                                    <Route path="/thank-you" element={<ThankYou setIsLoading={setIsLoading} />} />
                                    <Route path="/blog" element={<BlogList setIsLoading={setIsLoading} />} />
                                    <Route path="/blog/:id" element={<BlogDetail setIsLoading={setIsLoading} />} />
                                    <Route path="/blog/category/:categoryId" element={<CategoryList setIsLoading={setIsLoading} />} />
                                    <Route path="/search" element={<SearchResults setIsLoading={setIsLoading} />} />
                                </Routes>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </main>
                {(!isBlogPage || !isLoading) && <Footer />}
            </div>
        </HelmetProvider>
    );
}

export default App;
