import React from 'react';
import '../assets/styles/blog.css';

const NoResults = ({ query }) => {
  return (
    <div className="no-results">
      <p>「{query}」に一致する結果は見つかりませんでした。</p>
    </div>
  );
};

export default NoResults;
