import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/blog.css';

const BlogItem = ({ blog }) => (
  <li key={blog.id} className="blog-item">
    <Link to={`/blog/${blog.id}`} className="card-link">
      <div className="card">
        <div className="card-main">
          {blog.eyecatch ? (
            <img
              src={blog.eyecatch.url}
              alt={blog.title}
              className="card-image"
            />
          ) : (
            <div className="card-placeholder">No Image Available</div>
          )}
          <div className="card-content">
            <h2 className="card-title">{blog.title}</h2>
            <p className="card-timestamp">{new Date(blog.publishedAt).toLocaleDateString()}</p>
          </div>
        </div>
        {blog.description && (
          <div className="card-description-container">
            <p className="card-description">{blog.description}</p>
          </div>
        )}
      </div>
    </Link>
    {blog.description && <hr className="card-divider" />}
  </li>
);

export default BlogItem;
