import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/header.css'; 

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <div className="container">
                <Link to="/">
                    <img src={`${process.env.PUBLIC_URL}/logo-title.webp`} alt="会社ロゴ" className="logo" />
                </Link>
                <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                    <nav>
                        <ul>
                            <li><Link to="/service" onClick={toggleMenu}>サービス</Link></li>
                            <li><Link to="/about" onClick={toggleMenu}>会社情報</Link></li>
                            <li><Link to="/news" onClick={toggleMenu}>ニュース</Link></li>
                            <li><Link to="/contact" onClick={toggleMenu}>お問い合わせ</Link></li>
                        </ul>
                    </nav>
                </div>
                <button className="hamburger" onClick={toggleMenu}>
                    &#9776;
                </button>
            </div>
        </header>
    );
}

export default Header;
