import React from 'react';
import CommonHelmet from '../assets/helmet/CommonHelmet';

const About = () => {
    return (
        <div className="container">
            <CommonHelmet
                pageTitle="会社情報"
                keywords={["企業情報", "IR", "会社概要"]}
            />
            <h1>会社情報</h1>
            <p>株式会社Uematsuは日本の中小企業をIT企業に変えることを目指しています。</p>
            <p>会社の歴史、ミッション、ビジョンについて詳しく説明します。</p>
        </div>
    );
}

export default About;
