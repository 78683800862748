import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCategories } from '../api/microcms';
import '../assets/styles/header.css';

const BlogHeader = () => {
  const [categories, setCategories] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const data = await getCategories();
      setCategories(data);
    };
    fetchCategories();
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <header className="blog-header">
      <div className="container">
        <div className="logo-container">
          <Link to="/blog">
            <img src={`${process.env.PUBLIC_URL}/logo-title-blog.webp`} alt="DXBLOGロゴ" className="logo" />
          </Link>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="検索..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <button type="submit" className="search-button">検索</button>
          </form>
        </div>
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
        <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <nav>
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <Link to={`/blog/category/${category.id}`} onClick={toggleMenu}>
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default BlogHeader;
